import * as API from '../../../api'

export default {
  namespaced: true,
  state() {
    return {
      allTickers: [],
      networks: [],
      selectedNetwork: 'Выберите сеть',
      allTransaction: [],
      paymentsTypes: null,
    }
  },
  actions: {
    getAllAvailableTickers({ state }) {
      API.getAllAvailableTickers().then(response => {
         if(response) {
          state.allTickers = response
        }
      })
    },
    getAllTransaction({ state }, data) {
      API.getAllTransaction(data).then(response => {
        state.allTransaction = response
      })
    },
    getPaymentsTypes({ state }) {
      API.getPaymentsTypes().then(response => {
        state.paymentsTypes = response
      })
    },
    getPaymentsHistory({ state }, data) {
      API.getPaymentsHistory(data).then(response => {
        state.allTransaction = response.items
      })
    }
  },
  mutations: {
    CHOOSE_NETWORK(state, idxWallet) {
      state.networks = state.allTickers[idxWallet]?.blockchains
    },
    CHANGE_SELECTED_NETWORK(state, selected) {
      state.selectedNetwork = selected
    }
  },
  getters: {

  },
}
