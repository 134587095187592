<template>
  <div ref="modalRef">
    <button
      class="modal-buy-ticket__button"
      :class="{ 'card': card }"
      @click.stop="openModal"
    >
      {{ card ? getTranslation('Играть сейчас') : getTranslation('Купить билет') }}
    </button>
    <teleport to="body">
      <div
        v-show="isVisible"
        class="modal-buy-ticket"
        :class="{ 'modal-visible': isVisible }"
        :style="{ transform: `translateY(${translateY.toFixed()}px)` }"
      >
        <button
          class="modal-buy-ticket__btn-over"
          @mousedown="startDrag"
          @touchstart="startDrag"
          @mouseup="endDrag"
          @touchend="endDrag"
          @mousemove="onDrag"
          @touchmove="onDrag"
        ></button>
        <div class="modal-buy-ticket__quantity">
          <div class="modal-buy-ticket__title">{{ getTranslation('Кол-во билетов') }}</div>
          <CounterButton
            @plus-count="plusCount"
            @minus-count="minusCount"
            :countTicket="countTicket"
          />
          <RangeTicket
            :min="minRange"
            :max="maxRange"
            :value="countTicket"
            @change-tickets="changeTickets"
          />
        </div>
        <div class="modal-buy-ticket__block">
          <div class="modal-buy-ticket__info">
            <div class="modal-buy-ticket__amount">
              <div>{{ getTranslation('Сумма за') }} {{ countTicket }} {{ ticketsText }}</div>
              <div>{{ calcCostAmount }} USDT</div>
            </div>
            <div class="modal-buy-ticket__available">
              <div>{{ getTranslation('Доступный выигрыш') }}</div>
              <div class="modal-buy-ticket__available-cost">+ {{ room.game?.ticket_cost }} USDT</div>
            </div>
          </div>
          <button
            class="modal-buy-ticket__button"
            @click="buyTicket"
          >
            {{ getTranslation('Купить билет') }}
          </button>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import CounterButton from "@/components/UI/CounterButton/CounterButton.vue";
import RangeTicket from "@/components/UI/RangeTicket/RangeTicket.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    room: {},
    card: {
      default: false
    }
  },
  data() {
    return {
      isDragging: false,
      startY: 0,
      translateY: 100,
      isVisible: false,
      countTicket: 1,
      minRange: 1,
      maxRange: 6,
    }
  },
  methods: {
    buyTicket() {
      this.$store.dispatch('tickets/buyTicket', { id: this.room.id, count: { count: +this.countTicket }})
    },
    startDrag(event) {
      this.isDragging = true
      this.startY = event.touches ? event.touches[0].clientY : event.clientY
    },
    onDrag(event) {
      if (!this.isDragging) return

      const currentY = event.touches ? event.touches[0].clientY : event.clientY
      const deltaY = currentY - this.startY

      if (deltaY > 0) {
        this.translateY = deltaY
      }
    },
    endDrag() {
      if (!this.isDragging) return

      this.isDragging = false

      if (this.translateY > 150) {
        this.closeModal()
      } else {
        this.translateY = 0
      }
    },
    openModal() {
      this.isVisible = true
      this.translateY = 500
      setTimeout(() => {
        this.translateY = 0
      }, 0)
    },
    closeModal() {
      this.translateY = 500
      setTimeout(() => {
        this.isVisible = false
        this.$emit('close-buy-ticket')
      }, 300)
    },
    handleOutsideClick(event) {
      const modal = this.$refs.modalRef
      if (modal && !modal.contains(event.target)) {
        this.closeModal()
      }
    },
    plusCount() {
      if (this.countTicket < this.maxRange) {
        this.countTicket++
      }
    },
    minusCount() {
      if (this.countTicket > this.minRange) {
        this.countTicket -= 1
      }
    },
    changeTickets(value) {
      this.countTicket = value
    }
  },
  computed: {
    ...mapGetters('locale', ['getTranslation']),

    calcCostAmount() {
      return this.room.game?.ticket_cost * this.countTicket
    },
    ticketsText() {
      let count = this.countTicket

      if (count % 100 >= 5 && count % 100 <= 20) {
        return this.getTranslation('билетов')  ;
      }
      switch (count % 10) {
        case 1: return this.getTranslation('Билет');
        case 2:
        case 3:
        case 4: return this.getTranslation('билета') ;
        default: return this.getTranslation('билетов');
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick)
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick)
  },
  components: {
    CounterButton,
    RangeTicket
  }
}
</script>

<style lang="scss" scoped>
@import "modalBuyTicket";
</style>