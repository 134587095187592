<template>
  <div
      @click.stop="$emit('open-completed')"
      class="available-card"
  >
    <div class="available-card__image">
      <img :src="room.game?.image" alt="">
      <div></div>
    </div>
    <div class="available-card__block">
      <div class="available-card__head">
        <h3 class="available-card__title">{{ room.game?.title }}</h3>
        <!--        <div class="available-card__id">ID: {{ room.game?.id }}</div>-->
        <div class="available-card__info">
          <span class="available-card__cost">{{ calculatedCost.toFixed(2) }} USDT</span>
          <div class="available-card__win">
            <div class="available-card__chance">
              {{ getTranslation('Шанс') }}: <span>{{ room.game?.winning_tickets_percent }}</span>
            </div>
            <div class="available-card__winrate">
              {{ getTranslation('Процент побед') }}: <span>x{{ calcWinRate.toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="available-card__buy">
      {{ getTranslation('Смотреть подробнее') }}
    </button>
  </div>
</template>

<script>
import TicketsUserRoom from "@/components/TicketsUserRoom/TicketsUserRoom.vue";
import AllTicketsUser from "@/components/AllTicketsUser/AllTicketsUser.vue";
import { mapState, mapGetters } from "vuex";

export default {
  props: {
    room: {
      type: Object,
    }
  },
  data() {
    return {
      count: 1,
    }
  },
  computed: {
    ...mapState('tickets', ['ticketUserRoom']),
    ...mapGetters('locale', ['getTranslation']),
    ticketsText() {
      let count = this.count

      if (count % 100 >= 5 && count % 100 <= 20) {
        return 'билетов';
      }
      switch (count % 10) {
        case 1: return 'билет';
        case 2:
        case 3:
        case 4: return 'билета';
        default: return 'билетов';
      }
    },
    calculatedCost() {
      return this.count * this.room.game?.ticket_cost
    },
    calcWinRate() {
      const percentString = this.room.game?.winning_tickets_percent || "0%"
      const numericValue = parseFloat(percentString.replace("%", ""))
      return 100 / numericValue
    }
  },
  components: {
    TicketsUserRoom,
    AllTicketsUser
  }
}
</script>

<style lang="scss" scoped>
@import "cardCompleted";
</style>