<template>
  <div class="navigation-lottery" :style="{ top: `${dynamicTop}px` }">
    <div class="navigation-lottery__list">
      <RouterLink
        class="navigation-lottery__item"
        :class="{ 'navigation-lottery__item--active': activeTab === 'tickets' }"
        to="/dashboard/available-lotteries"
        @click="setActiveTab('tickets')"
      >
        {{ getTranslation('Доступные лотереи') }}
      </RouterLink>
      <RouterLink
        class="navigation-lottery__item"
        :class="{ 'navigation-lottery__item--active': activeTab === 'history' }"
        to="/dashboard/history-participation"
        @click="setActiveTab('history')"
      >
        {{ getTranslation('Мои билеты') }}
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeTab: 'tickets',
      dynamicTop: 68,
      lastScrollY: 0,
    }
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab
    },
    handleScroll() {
      const scrollY = window.scrollY; // Текущее значение прокрутки

      // Если скролл вниз
      if (scrollY > 68) {
        this.dynamicTop = 12; // За пределами верхней области top фиксируется на 12px
      }
      // Если скролл вверх и осталось меньше 68px до верха
      else {
        this.dynamicTop = 68 - scrollY; // top изменяется пропорционально расстоянию до верха
      }

      // Обновляем последнее значение прокрутки
      this.lastScrollY = scrollY;
    },
  },
  computed: {
    ...mapGetters('locale', ['getTranslation'])
  },
  mounted() {
    switch (this.$route.path) {
      case "/dashboard/history-participation":
        this.activeTab = 'tickets'
        break
      case "/dashboard/operations-history":
        this.activeTab = 'history'
        break
    }
    this.lastScrollY = window.scrollY;
    window.addEventListener("scroll", this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  },
}
</script>

<style lang="scss" scoped>
@import "navigationLottery";
</style>