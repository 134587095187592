<template>
  <div class="referral-item">
    <div class="referral-item__user">
      <div class="referral-item__avatar">
        <img :src="referral.referral_user?.avatar" alt="">
      </div>
      <span class="referral-item__name">{{ referral.referral_user?.username }}</span>
    </div>
    <div class="referral-item__income">
      <span>{{ getTranslation('Ваш доход') }}</span>
      <span>{{ referral.transaction?.value }} <span> USDT</span></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    referral: {
      type: Object,
    }
  },
   computed:{
     ...mapGetters('locale', ['getTranslation'])
   }
}
</script>

<style lang="scss" scoped>
@import "referralItem";
</style>