<template>
  <div
      class="available-card"
      :class="{ 'soon': room.game?.is_available_soon }"
  >
    <div v-if="room.game?.is_available_soon" class="available-card__soon">
      <span>{{ getTranslation('Скоро будет') }} </span>
      <img src="@/assets/svg/soon-rectangle.svg" alt="">
    </div>
    <div class="available-card__image">
      <img :src="room.game?.image" alt="">
      <div></div>
    </div>
    <div class="available-card__block">
      <div class="available-card__head">
        <h3 class="available-card__title">{{ room.game?.title }}</h3>
<!--        <div class="available-card__id">ID: {{ room.game?.id }}</div>-->
        <div class="available-card__info">
          <span class="available-card__cost">{{ calculatedCost.toFixed(2) }} USDT</span>
          <div class="available-card__win">
            <div class="available-card__chance">
              {{ getTranslation('Шанс') }}: <span>{{ room.game?.winning_tickets_percent }}</span>
            </div>
            <div class="available-card__winrate">
              {{ getTranslation('Процент побед') }}: <span>x{{ calcWinRate.toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="available-card__space-between">-->
<!--        <div class="available-card__progress">-->
<!--          <div class="available-card__progress-text">-->
<!--            <span>{{getTranslation('Участники')}}</span>-->
<!--            <span>{{ room?.participants_count }}/{{ room.game?.max_participants_count }}</span>-->
<!--          </div>-->
<!--          <div class="available-card__progress-bar">-->
<!--            <div class="progress-bar__bg"></div>-->
<!--            <div :style="{width: setPercent + '%' }" class="progress-bar__fill"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="available-card__foot">-->
<!--          <TicketsUserRoom :tickets="room?.user_tickets_count "/>-->
<!--          <AllTicketsUser :tickets="room?.total_tickets_count" />-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <ModalBuyTicket :room="room" :card="true" />
  </div>
</template>

<script>
import CounterButton from "@/components/UI/CounterButton/CounterButton.vue";
import AllTicketsUser from "@/components/AllTicketsUser/AllTicketsUser.vue";
import TicketsUserRoom from "@/components/TicketsUserRoom/TicketsUserRoom.vue";
import { mapGetters } from "vuex";
import ModalBuyTicket from "@/components/Modals/ModalBuyTicket/ModalBuyTicket.vue";

export default {
  props: {
    room: {
      type: Object,
    },
    user: {},
  },
  data () {
    return {
      count: '1',
    }
  },
  methods: {
    buyTicket() {
      // this.$store.dispatch('tickets/buyTicket', { id: this.room.id, count: { count: +this.count }})
      this.$emit('buy-ticket', this.room)
    },
  },
  computed: {
    ...mapGetters('locale', ['getTranslation']),
    setPercent() {
      return Math.round(this.room?.participants_count / this.room.game?.max_participants_count * 100)
    },
    ticketsText() {
      let count = this.room?.user_tickets_count

      if (count % 100 >= 5 && count % 100 <= 20) {
        return this.getTranslation('билетов')  ;
      }
      switch (count % 10) {
        case 1: return this.getTranslation('билет');
        case 2:
        case 3:
        case 4: return this.getTranslation('билета') ;
        default: return this.getTranslation('билетов');
      }
    },
    calculatedCost() {
      return this.count * this.room.game?.ticket_cost
    },
    calcWinRate() {
      const percentString = this.room.game?.winning_tickets_percent || "0%"
      const numericValue = parseFloat(percentString.replace("%", ""))
      return 100 / numericValue
    }
  },
  components: {
    ModalBuyTicket,
    TicketsUserRoom,
    AllTicketsUser,
    CounterButton
  },
}
</script>

<style lang="scss" scoped>
@import "availableCard";
</style>