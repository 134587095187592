<template>
  <div ref="select" class="select">
    <div
      @click="toggleSelect"
      :class="{ 'open': openSelect }"
      class="select__selected"
    >
      <span>{{ selected }}</span>
      <div class="select__image">
        <img :class="{ 'rotate-180': openSelect }" src="@/assets/svg/ChevronDown.svg" alt="">
      </div>
    </div>
    <div v-if="openSelect" class="select__list">
      <div
        v-for="item in dataSelect"
        :key="item.id"
        class="select__item"
        @click="changeSelected(item)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataSelect: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      openSelect: false,
      selected: this.dataSelect[0]?.name || "Выберите элемент",
    }
  },
  methods: {
    toggleSelect() {
      this.openSelect = !this.openSelect
      if (this.openSelect) {
        document.addEventListener('click', this.handleClickOutside)
      } else {
        document.removeEventListener('click', this.handleClickOutside)
      }
    },
    changeSelected(item) {
      this.selected = item.name
      this.openSelect = false
      this.$emit('change-type', item.value)
      document.removeEventListener('click', this.handleClickOutside)
    },
    handleClickOutside(event) {
      if (this.$refs.select && !this.$refs.select.contains(event.target)) {
        this.openSelect = false
        document.removeEventListener('click', this.handleClickOutside)
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }
}
</script>

<style lang="scss" scoped>
@import "select";
</style>