<template>
  <div class="lottery">
    <div class="_container">
      <div class="lottery__wrapper">
        <div class="lottery__header">
          <div class="lottery__header-bg">
            <img :src="room.game?.image" alt="">
            <div></div>
          </div>
          <div class="lottery__header-block">
            <h2 class="lottery__title">{{ room.game?.title }}</h2>
            <div class="lottery__header-info">
              <span class="lottery__header-cost">{{ calculatedCost.toFixed(2) }} USDT</span>
              <div class="lottery__header-win">
                <div class="lottery__header-chance">
                  Chance: <span>{{ room.game?.winning_tickets_percent }}</span>
                </div>
                <div class="lottery__header-winrate">
                  Win rate: <span>x{{ calcWinRate.toFixed(2) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="lottery__back">
            <button @click="backRoute">
              <img src="@/assets/svg/arrow-left-white.svg" alt="">
            </button>
            <button>
              <img src="@/assets/svg/icons_telegram-white.svg" alt="">
            </button>
          </div>
        </div>
        <div class="lottery__container">
          <div class="lottery__block">
            <div class="lottery__block-info">
              <div>
                You have
                <span>{{ room?.user_tickets_count + ' ' + ticketCountText(room?.user_tickets_count) }}</span>
              </div>
              <div>
                Bought
                <span>{{ room?.total_tickets_count + ' ' + ticketCountText(room?.total_tickets_count)  }}</span>
              </div>
            </div>
            <div v-if="room?.participants" class="lottery__member-list">
              <LotteryMember
                v-for="participant in room?.participants"
                :participant="participant"
              />
            </div>
            <div v-if="room.participants?.length === 0" class="lottery__member-empty">
              <span>{{getTranslation('Еще никто не учавствует')}}</span>
              <img src="@/assets/svg/wave-empty-container.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvailableCard from "@/components/AvailableCard/AvailableCard.vue";
import LotteryMember from "@/components/LotteryMember/LotteryMember.vue";
import ButtonBack from "@/components/UI/ButtonBack/ButtonBack.vue";
import LotteryCardCompleted from "@/components/LotteryCardCompleted/LotteryCardCompleted.vue";
import {mapState, mapGetters} from "vuex";
import ModalBuyTicket from "@/components/Modals/ModalBuyTicket/ModalBuyTicket.vue";

export default {
  data() {
    return {
      path: '',
      count: 1
    }
  },
  methods: {
    backRoute () {
      if (this.path) {
        this.$router.push(this?.path)
      } else {
        this.$router.push('/dashboard/available-lotteries')
      }
    },
    ticketCountText(count) {
      if (count === 1) {
        return 'ticket'
      } else if (count >=2 && count <= 4) {
        return 'tickets'
      } else {
        return 'tickets'
      }
    },
  },
  mounted() {
    this.$store.dispatch('rooms/getRoom', +this.$route.params.id)
  },
  computed: {
    ...mapGetters('locale', ['getTranslation']),
    ...mapState('rooms', ['room']),
    participantsCount () {
      let tickets = this.room.participants?.length
      if (tickets === 1) {
        return 'пользователь'
      } else if (tickets >=2 && tickets <= 4) {
        return 'пользователя'
      } else {
        return 'пользователей'
      }
    },
    calculatedCost() {
      return this.count * this.room.game?.ticket_cost
    },
    calcWinRate() {
      const percentString = this.room.game?.winning_tickets_percent || "0%"
      const numericValue = parseFloat(percentString.replace("%", ""))
      return 100 / numericValue
    },
  },
  components: {
    ModalBuyTicket,
    LotteryCardCompleted,
    ButtonBack,
    LotteryMember,
    AvailableCard,
  },
  created() {
    this.path = this.$route.query.previousPath;
  }
}
</script>

<style lang="scss" scoped>
@import "completedLottery";
</style>