<template>
  <div class="fortune-wheel__item">
    <div class="fortune-wheel__item-user">
      <img :src="winner?.avatar" alt="">
      <div class="fortune-wheel__item-info">
        <span>{{ winner?.username }}</span>
        <div class="fortune-wheel__item-status">
          {{ getTranslation('Победа') }}
        </div>
      </div>
    </div>
    <div class="fortune-wheel__item-reward">
      + {{ winner?.name }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    winner: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('locale', ['getTranslation'])
  }
}
</script>

<style lang="scss" scoped>
@import "FortuneWheelItem";
</style>