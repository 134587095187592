<template>
  <div ref="clotRef">
    <button
      class="clot-window__profile"
      @click.stop="openModal"
    >
      <img class="header__profile-icon" src="@/assets/svg/CLOT_icon.svg" alt="">
      <div class="header__profile-info">
        <span>100.00</span>
      </div>
    </button>
    <teleport to="body">
      <div
        v-show="isVisibleClot"
        class="clot-window"
        :class="{ 'clot-window__visible': isVisibleClot }"
        :style="{ transform: `translateY(${translateY.toFixed()}px)` }"
      >
        <button
          class="clot-window__btn-over"
          @mousedown="startDrag"
          @touchstart="startDrag"
          @mouseup="endDrag"
          @touchend="endDrag"
          @mousemove="onDrag"
          @touchmove="onDrag"
        ></button>
        <div class="clot-window__block">
          <div class="clot-window__head">
            <img class="clot-window__icon" src="@/assets/svg/CLOT_icon.svg" alt="">
            <div class="clot-window__title">
              <div>CLOT</div>
              <span>TOKEN</span>
            </div>
          </div>
          <div class="clot-window__content">
            <p>Каждый пользователь платформы получает шанс бесплатно выиграть токены CLOT, вращая «Колесо фортуны». Это стимулирует ежедневное взаимодействие и повышает вовлечённость.</p>
            <p>Фарминг через участие в лотереях: Участвуя в лотереях платформы, вы зарабатываете CLOT если вдруг ваш лотарейный билет был не выиграшный. Токен делает наши лотареи практически безспроигрышными. Чем больше активности, тем выше ваши награды!</p>
            <p>Экономическая модель: Токен CLOT разработан с учётом баланса спроса и предложения. Он интегрирован в экосистему, предлагая пользователям различные способы использования, включая внутриигровые покупки, участие в эксклюзивных розыгрышах и доступ к премиум-функциям.</p>
            <p>Торговля: Токен доступен для торговли на биржах. Чтобы узнать полный список бирж, где представлен CLOT, изучите вайтпепер проекта – там вы найдете детальное описание всех партнёрских платформ и механизмов работы токена.</p>
          </div>
          <button @click="closeModal" class="clot-window__btn">
            Whitepaper
          </button>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isDragging: false,
      startY: 0,
      translateY: 100,
      isVisibleClot: false,
      countTicket: 1,
      minRange: 1,
      maxRange: 6,
    }
  },
  methods: {
    buyTicket() {
      this.$store.dispatch('tickets/buyTicket', { id: this.room.id, count: { count: +this.countTicket }})
    },
    startDrag(event) {
      this.isDragging = true
      this.startY = event.touches ? event.touches[0].clientY : event.clientY
    },
    onDrag(event) {
      if (!this.isDragging) return

      const currentY = event.touches ? event.touches[0].clientY : event.clientY
      const deltaY = currentY - this.startY

      if (deltaY > 0) {
        this.translateY = deltaY
      }
    },
    endDrag() {
      if (!this.isDragging) return

      this.isDragging = false

      if (this.translateY > 150) {
        this.closeModal()
      } else {
        this.translateY = 0
      }
    },
    openModal() {
      this.isVisibleClot = true
      this.translateY = 600
      setTimeout(() => {
        this.translateY = 0
      }, 0)
    },
    closeModal() {
      this.translateY = 600
      setTimeout(() => {
        this.isVisibleClot = false
        this.$emit('close-buy-ticket')
      }, 300)
    },
    handleOutsideClick(event) {
      const modal = this.$refs.clotRef
      if (modal && !modal.contains(event.target)) {
        this.closeModal()
      }
    },
  },
  computed: {
    ...mapGetters('locale', ['getTranslation']),
  },
  mounted() {
    document.addEventListener("click", this.handleOutsideClick)
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick)
  },
}
</script>

<style lang="scss" scoped>
@import "cLOTWindow";
</style>