<template>
  <div class="replenishment-payment">
    <div class="replenishment-payment__block">
      <div class="replenishment-payment__block-head">
        <div class="replenishment-payment__block-title">
          <span>{{getTranslation('Проверьте сумму')}}</span>
        </div>
        <div class="replenishment-payment__block-time">
          <span>{{getTranslation('Время оплаты: ')}} </span>
          <span>{{ formattedCountdownTime  }}</span>
        </div>
      </div>
      <div class="replenishment-payment__block-input">
        <div class="replenishment-payment__block-inner">
          <span>{{ amount }}</span>
        </div>
        <div class="replenishment-payment__copy" @click="copyAmount">
          <img src="@/assets/svg/lucide_copy-white.svg">
        </div>
      </div>
    </div>
    <div class="replenishment-payment__block">
      <div class="replenishment-payment__block-head">
        <div class="replenishment-payment__block-address">
          <span>Address</span>
          <div>
            <span>USDT</span>
            <span>RC-20</span>
          </div>
        </div>
      </div>
      <div class="replenishment-payment__block-input">
        <span v-if="!depositInformation">{{ getTranslation('Загрузка адреса...') }}</span>
        <span v-else>{{ depositInformation[0]?.address }}</span>
        <div class="replenishment-payment__copy" @click="copyAmount">
          <img src="@/assets/svg/lucide_copy-white.svg">
        </div>
      </div>
    </div>
    <div class="replenishment-payment__warning">
      <img src="@/assets/svg/fluent_warning-gradient.svg" alt="warning">
      <div>
        <span>{{ getTranslation('Реквизиты для оплаты меняются с каждым платёжом!') }}</span>
      </div>
    </div>
    <Button :color="'newGreen'" @click="replenishmentPayment">
      {{ getTranslation('Я оплатил') }}
    </Button>
    <div class="replenishment-payment__block">
      <div class="replenishment-payment__block-head">
        <div class="replenishment-payment__block-title">
          <span>{{ getTranslation('После отправки нажмите на кнопку “Я оплатил”') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import {mapGetters, mapState} from "vuex";
import {login} from "@/api";

export default {
  props: {
    amount: {}
  },
  data() {
    return {

    }
  },
  methods: {
    replenishmentPayment() {
      this.$store.commit('notifications/PAYMENT_MODAL', {
        title: this.getTranslation() ,
        text: this.getTranslation('Для проверки статуса операции перейдите на страницу')
      })
    },
    copyAmount() {
      const amount = this.amount
      if (amount) {
        navigator.clipboard.writeText(amount).then(() => {
          this.$store.commit('notifications/SUCCESS_CHANGE', this.getTranslation('Сумма скопирована') )
        })
      }
    },
    copyAddress() {
      const address = this.depositInformation[0]?.address
      if (address) {
        navigator.clipboard.writeText(address).then(() => {
            this.$store.commit('notifications/SUCCESS_CHANGE', this.getTranslation('Адрес скопирован') )
        })
      }
    },
  },
  computed: {
    ...mapGetters('deposit', ['formattedCountdownTime']),
    ...mapGetters('locale', ['getTranslation']),
    ...mapState('deposit', ['depositInformation'])
  },
  beforeUnmount() {
    this.$store.commit('deposit/stopCountdown')
  },
  components: {
    Button,
  },
}
</script>

<style lang="scss" scoped>
@import "replenishmentPayment";
</style>