<template>
  <div class="lottery">
    <div class="_container">
      <div class="lottery__wrapper">
        <div class="lottery__header">
          <div class="lottery__header-bg">
            <img :src="room.game?.image" alt="">
            <div></div>
          </div>
          <div class="lottery__header-block">
            <h2 class="lottery__title">{{ room.game?.title }}</h2>
            <div class="lottery__header-info">
              <span class="lottery__header-cost">{{ calculatedCost.toFixed(2) }} USDT</span>
              <div class="lottery__header-win">
                <div class="lottery__header-chance">
                  {{ getTranslation('Шанс') }}: <span>{{ room.game?.winning_tickets_percent }}</span>
                </div>
                <div class="lottery__header-winrate">
                  {{ getTranslation('Процент побед') }}: <span>x{{ calcWinRate.toFixed(2) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="lottery__back">
            <button @click="backRoute">
              <img src="@/assets/svg/arrow-left-white.svg" alt="">
            </button>
            <button>
              <img src="@/assets/svg/icons_telegram-white.svg" alt="">
            </button>
          </div>
        </div>
        <div class="lottery__btn-buy">
          <ModalBuyTicket :room="room" />
        </div>
<!--        <div class="lottery__header">-->
<!--          <div class="lottery__head">-->
<!--            <ButtonBack @click="backRoute" />-->
<!--          </div>-->
<!--          <div class="lottery__transaction">-->
<!--            <span>{{getTranslation('Смарт контракт лотереи')}}</span>-->
<!--            <a :href="room.game?.web3_contract" target="_blank">-->
<!--              {{ room.game?.web3_contract }}-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
        <div class="lottery__container">
<!--          <div class="lottery__mobile">-->
<!--            <div class="lottery__head-mobile">-->
<!--              <div>-->
<!--                <h2 class="lottery__title">{{ room.game?.title }}</h2>-->
<!--                <h4 class="lottery__id">{{ room.game?.id }}</h4>-->
<!--              </div>-->
<!--              <ButtonBack @click="backRoute" />-->
<!--            </div>-->
<!--            <LotteryCard-->
<!--                :room="room"-->
<!--            />-->
<!--          </div>-->
          <div class="lottery__block">
            <div class="lottery__block-info">
              <div>
                You have
                <span>{{ room?.user_tickets_count + ' ' + ticketCountText(room?.user_tickets_count) }}</span>
              </div>
              <div>
                Bought
                <span>{{ room?.total_tickets_count + ' ' + ticketCountText(room?.total_tickets_count)  }}</span>
              </div>
            </div>
            <div v-if="room?.participants" class="lottery__member-list">
              <LotteryMember
                  v-for="participant in room?.participants"
                  :participant="participant"
              />
            </div>
            <div v-if="room.participants?.length === 0" class="lottery__member-empty">
              <span>{{ getTranslation('Еще никто не учавствует') }}</span>
              <img src="@/assets/svg/wave-empty-container.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvailableCard from "@/components/AvailableCard/AvailableCard.vue";
import LotteryMember from "@/components/LotteryMember/LotteryMember.vue";
import ButtonBack from "@/components/UI/ButtonBack/ButtonBack.vue";
import LotteryCard from "@/components/LotteryCard/LotteryCard.vue";
import {mapState, mapGetters} from "vuex";
import ModalBuyTicket from "@/components/Modals/ModalBuyTicket/ModalBuyTicket.vue";
export default {
  data() {
    return {
      path: '',
      count: '1',
    }
  },
  methods: {
    backRoute() {
      if (this.path) {
        this.$router.push(this?.path)
      } else {
        this.$router.push('/dashboard/available-lotteries')
      }
    },
    startRequestInterval() {
      this.intervalId = setInterval(() => {
        this.$store.dispatch('rooms/getRoom', +this.$route.params.id)
      }, 5000);
    },
    stopRequestInterval() {
      clearInterval(this.intervalId);
    },
    buyTicket() {
      this.$emit('buy-ticket', this.room)
    },
    ticketCountText(count) {
      if (count === 1) {
        return 'ticket'
      } else if (count >=2 && count <= 4) {
        return 'tickets'
      } else {
        return 'tickets'
      }
    },
  },
  mounted() {
    this.$store.dispatch('rooms/getRoom', +this.$route.params.id)
  },
  computed: {
    ...mapState('rooms', ['room']),
    ...mapGetters('locale', ['getTranslation']),
    calculatedCost() {
      return this.count * this.room.game?.ticket_cost
    },
    calcWinRate() {
      const percentString = this.room.game?.winning_tickets_percent || "0%"
      const numericValue = parseFloat(percentString.replace("%", ""))
      return 100 / numericValue
    },
  },
  created() {
    this.path = this.$route.query.previousPath;

    this.startRequestInterval();
  },
  beforeUnmount() {
    this.stopRequestInterval();
  },
  components: {
    ModalBuyTicket,
    LotteryCard,
    ButtonBack,
    LotteryMember,
    AvailableCard
  }
}
</script>

<style lang="scss" scoped>
@import "lottery";
</style>