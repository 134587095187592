<template>
  <div class="settings">
    <div class="_container">
      <div class="settings__wrapper">
        <h2 class="settings__title">{{getTranslation('Настройки')}}</h2>
        <div class="settings__head">
          <div  class="settings__avatar">
            <div v-if="imageUrl" class="settings__avatar-img">
              <img :src="imageUrl" alt="avatar">
            </div>
            <div v-if="!imageUrl" class="settings__avatar-img">
              <img :src="user?.avatar" alt="avatar">
            </div>
            <input
                type="file"
                id="file"
                @change="handleFileUpload"
                accept="image/png, image/jpeg"
            >
            <label
                class="settings__avatar-edit"
                for="file"
            >
              <img src="@/assets/svg/pen-blue.svg" alt="pen">
            </label>
          </div>
          <div class="settings__info">
            <div
                v-if="!editUser"
                class="settings__rename"
            >
              <span class="settings__name">{{ user?.username }}</span>
              <button
                  @click="editUser = true"
                  class="settings__rename-button"
              >
                <img src="@/assets/svg/edit-name.svg" alt="">
              </button>
            </div>
            <span class="settings__name-mobile">{{ user?.username }}</span>
            <div
                v-if="editUser"
                class="settings__rename-open"
            >
              <input
                  type="text"
                  :placeholder="getTranslation('Новое имя пользователя')"
                  v-model="username"
              >
              <div class="settings__rename-btns">
                <button @click="changeUsername">{{getTranslation('Подтвердить')}}</button>
                <button @click="editUser = false">{{getTranslation('Отмена')}}</button>
              </div>
            </div>
            <span>{{getTranslation('Электронная почта:')}}
              <span>{{ user?.email }}</span>
            </span>
          </div>
        </div>

        <div class="settings__rename-mobile">
          <Button
              v-if="!editUser"
              color="newGreen"
              @click="editUser = true"
          >
            {{getTranslation('Сменить имя пользователя')}}
          </Button>
          <div
              v-if="editUser"
              class="settings__rename-open__mobile"
          >
            <input
                type="text"
                :placeholder="getTranslation('Новое имя пользователя')"
                v-model="username"
            >
            <div class="settings__rename-btns">
              <button @click="changeUsername">{{getTranslation('Подтвердить')}}</button>
              <button @click="editUser = false">{{getTranslation('Отмена')}}</button>
            </div>
          </div>
        </div>
        <Button @click="$router.push('/dashboard/replenishment-withdrawal/deposit')" color="gray">
          <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_425)">
              <path d="M20.1299 7.55558H18.9444V4.50003C18.9444 4.33795 18.88 4.18251 18.7654 4.06791C18.6508 3.9533 18.4953 3.88892 18.3333 3.88892H3.66659C3.50451 3.88892 3.34907 3.82453 3.23446 3.70993C3.11986 3.59532 3.05547 3.43988 3.05547 3.27781C3.05547 3.11573 3.11986 2.96029 3.23446 2.84569C3.34907 2.73108 3.50451 2.6667 3.66659 2.6667H18.0888C18.2509 2.6667 18.4063 2.60231 18.5209 2.48771C18.6355 2.3731 18.6999 2.21766 18.6999 2.05558C18.6999 1.89351 18.6355 1.73807 18.5209 1.62346C18.4063 1.50886 18.2509 1.44447 18.0888 1.44447H3.66659C3.43063 1.43961 3.19603 1.48128 2.97618 1.5671C2.75634 1.65293 2.55555 1.78123 2.3853 1.94467C2.21505 2.10811 2.07867 2.30349 1.98395 2.51965C1.88923 2.73581 1.83802 2.96852 1.83325 3.20447V16.0378C1.83405 16.3698 1.90033 16.6983 2.02829 17.0046C2.15625 17.3109 2.34338 17.589 2.57896 17.8228C2.81454 18.0567 3.09394 18.2418 3.40117 18.3676C3.7084 18.4933 4.03741 18.5572 4.36936 18.5556H18.3333C18.4953 18.5556 18.6508 18.4912 18.7654 18.3766C18.88 18.262 18.9444 18.1066 18.9444 17.9445V14.8889H20.1299C20.2071 14.8948 20.2846 14.8848 20.3577 14.8596C20.4309 14.8344 20.4981 14.7946 20.5553 14.7425C20.6125 14.6905 20.6585 14.6273 20.6904 14.5568C20.7224 14.4864 20.7396 14.4101 20.741 14.3328V8.2217C20.743 8.05375 20.681 7.89136 20.5674 7.76759C20.4539 7.64383 20.2974 7.56801 20.1299 7.55558ZM19.5555 13.6667H14.3121C13.6859 13.6444 13.094 13.3744 12.6666 12.9161C12.2393 12.4578 12.0112 11.8486 12.0327 11.2223C12.0112 10.5959 12.2393 9.98672 12.6666 9.5284C13.094 9.07007 13.6859 8.80011 14.3121 8.77781H19.5555V13.6667Z" fill="#697383"/>
              <path d="M14.6911 12.0899C15.1973 12.0899 15.6077 11.6795 15.6077 11.1733C15.6077 10.667 15.1973 10.2566 14.6911 10.2566C14.1848 10.2566 13.7744 10.667 13.7744 11.1733C13.7744 11.6795 14.1848 12.0899 14.6911 12.0899Z" fill="#697383"/>
            </g>
            <defs>
              <clipPath id="clip0_1_425">
                <rect width="22" height="22" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          Пополнение/вывод средств
        </Button>
        <SettingsContainer
            @open-modal-change-password="$emit('open-modal-change-password')"
            @open-modal-change-email="$emit('open-modal-change-email')"
            @open-modal-2fa="$emit('open-modal-2fa')"
            @open-disabled-2fa="$emit('open-disabled-2fa')"
        />
        <button
            @click="logout"
            class="settings__exit-button"
        >
          <img src="@/assets/svg/settings-exit.svg" alt="">
          <span>{{getTranslation('Выйти из аккаунта')}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsContainer from "@/components/SettingsContainer/SettingsContainer.vue";
import Button from "@/components/UI/Button/Button.vue";
import {changeUsername} from "@/api";
import {mapGetters} from "vuex";

export default {
  props: {
    user: {},
  },
  data () {
    return {
      selectedFile: null,
      imageUrl: null,
      editUser: false,
      username: ''
    }
  },
  methods: {
    logout() {
      document.cookie = "token=;  max-age=0;"

      localStorage.removeItem('authToken')
      localStorage.removeItem('user')

      this.$store.commit('auth/SET_AUTH', false)
      this.$router.push('/')
    },
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0]
      this.imageUrl = URL.createObjectURL(this.selectedFile)

      this.$store.dispatch('settings/changeAvatar', { avatar: this.selectedFile })
    },
    changeUsername () {
      this.$store.dispatch('settings/changeUsername', { username: this.username })
      this.editUser = false
      this.username = ''
    }
  },
  mounted() {

  },
  computed:{
    ...mapGetters('locale', ['getTranslation'])
  },
  components: {
    Button,
    SettingsContainer,
  }
}
</script>

<style lang="scss" scoped>
@import "settings";
</style>