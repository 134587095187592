<template>
  <div class="range-ticket">
    <input
      type="range"
      class="range-ticket__slider"
      :min="min"
      :max="max"
      :step="1"
      :value="value"
      @input="onInputChange($event.target.value)"
    >
    <div
      class="range-ticket__fill"
      :style="{ 'width': `${widthFill}%`}">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    value: {
      type: Number,
      required: true,
      default: 1
    }
  },
  data() {
    return {
      widthFill: 0
    }
  },
  watch: {
    value(newValue) {
      this.updateWidthFill(newValue)
    }
  },
  methods: {
    onInputChange(newValue) {
      const numericValue = Number(newValue)
      this.$emit('change-tickets', numericValue)
      this.updateWidthFill(numericValue)
    },
    updateWidthFill(value) {
      this.widthFill = ((value - this.min) / (this.max - this.min)) * 100
    }
  },
  mounted() {
    this.updateWidthFill(this.value)
  }
};
</script>

<style lang="scss" scoped>
@import "rangeTicket";
</style>
