<template>
  <div class="language-switcher">
    <Button
      class="language-switcher__btn"
      v-if="!isSwitcherOppened"
      @click="changeSwitcher"
      color="dark"
    >
      <img
        class="language-switcher__img"
        :src="getSelectedLanguageIcon()"
        alt=""
        width="40px"
        height="22px"
        srcset=""
      />
    </Button>
    <div v-else v-click-outside="closeSwitcher">
      <div class="language-selector">
        <Button
          @click="changeSwitcher"
          color="dark"
          class="language-switcher__btn"
        >
          <img
            class="language-switcher__img"
            :src="getSelectedLanguageIcon()"
            alt=""
            width="40px"
            height="22px"
            srcset=""
          />
        </Button>

        <div class="language-switcher__dropdown-block">
          <ul class="language-switcher__dropdown">
            <li v-for="lang in locale" @click="changeLanguage(lang.title)">
              <img :src="lang.image" />
              <span>{{ lang.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/UI/Button/Button.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "LanguageSwitcher",
  components: { Button },
  data() {
    return {
      isSwitcherOppened: false,
    };
  },
  methods: {
    changeSwitcher() {
      event.stopPropagation();
      this.isSwitcherOppened = !this.isSwitcherOppened;
    },
    changeLanguage(title) {
      this.$store.dispatch("locale/setCurrentLocale", title);
      this.isSwitcherOppened = !this.isSwitcherOppened;
    },
    closeSwitcher() {
      this.isSwitcherOppened = false;
    },
  },
  computed: {
    ...mapGetters("locale", ["getSelectedLanguageIcon"]),
    ...mapState("locale", ["locale"]),
  },
};
</script>

<style lang="scss" scoped>
@import "languageSwitcher";
</style>
