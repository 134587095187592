<template>
  <div class="replenishment-withdrawal">
    <div class="_container">
      <div class="replenishment-withdrawal__wrapper">
        <div class="replenishment-withdrawal__header">
          <button
            class="replenishment-withdrawal__settings"
            @click="$router.push('/dashboard/settings')"
          >
            <img src="@/assets/svg/fluent_settings-white.svg" alt="">
          </button>
          <div class="replenishment-withdrawal__user">
            <img :src="user?.avatar" alt="avatar">
            <div>
              TG ID: <span>3435334</span>
            </div>
          </div>
        </div>
        <div class="replenishment-withdrawal__container">
          <RWTabs
              @openRWH="openRWH"
          />
          <RouterView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RWTabs from "@/components/UI/RWTabs/RWTabs.vue";
import Replenishment from "@/components/Replenishment/Replenishment.vue";
import Withdrawal from "@/components/Withdrawal/Withdrawal.vue";
import {mapGetters, mapState} from "vuex";

export default {
  data() {
    return {
      openRW: 0,
    }
  },
  methods: {
    openRWH(id) {
      this.openRW = id
    }
  },
  computed:{
    ...mapGetters('locale', ['getTranslation']),
    ...mapState("auth", ["user"]),
  },
  components: {
    Withdrawal,
    Replenishment,
    RWTabs,
  },
  mounted() {
    const path = '/dashboard/replenishment-withdrawal/'
    switch (this.$route.path) {
      case `${path}deposit`:
        this.openRW = 0;
        break;
      case `${path}withdrawal`:
        this.openRW = 1;
        break;
      case `/dashboard/operations-history`:
        this.openRW = 2;
        break;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "replenishmentWithdrawal";
</style>